.mentions {
    width: 100%;
}

.mentions--multiLine .mentions__control textarea {
    border-radius: 4px;
    padding: 7px 0 7px 10px;
   
}
.mentions--multiLine .mentions__control textarea:focus {
    color: #596882;
    background-color: #fff;
    border-color: #485e9029;
    outline: 0;
}

.mentions--multiLine .mentions__highlighter {
    padding: 7px 10px;
    line-height: normal;
}

.mentions__suggestions {
    z-index: 10000 !important;
    border: 1px solid #485e9029;
}
.mentions__mention {
    position: relative;
    z-index: 1;
    color: #0E405F;
    pointer-events: cursor;
  }